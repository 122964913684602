<template>
  <section>
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <p>Dashboard area cliente:<br />Calendario Google, ecc. ... dashboard da definire</p>
      </b-col>
      
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      userData: {},
    }
  },
  created() {
    // dati reali
    //this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)

  },
}
</script>
